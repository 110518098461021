/* eslint-disable no-return-assign */
import React from 'react';
import styled from 'styled-components';

import ButtonWithIcon from '../../mobiauto.web-ui-components.components.button-with-icon/ButtonWithIcon';
import colors from '../../mobiauto.web-ui-components.components.colors/colors';
import Flex from '../../mobiauto.web-ui-components.flex';

const Label = styled.div`
  padding: 0px 5px;
  cursor: pointer;
`;

const Disabled = styled(Flex)`
  height: 20px;
  width: 20px;
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
  border-color: #dcdcdc;
  background: #dcdcdc;
`;

const Error = styled(Flex)`
  height: 20px;
  width: 20px;
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
  background-color: ${({ checked, color }) =>
    checked ? `${color || colors.primary.medium}` : 'white'};
  border-color: ${({ checked, color }) =>
    checked ? `${color || colors.primary.medium}` : `${colors.warning.light}`};
  &:hover {
    border-color: ${({ checked, color }) =>
      checked ? color || colors.primary.medium : colors.warning.medium};
    background-color: ${({ checked, color }) =>
      checked ? `${color || colors.primary.medium}` : 'white'};
  }
`;

const Success = styled(Flex)`
  height: 20px;
  width: 20px;
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
  background-color: ${({ checked, color }) => (checked ? `${color}` : 'white')};
  border-color: ${({ checked, color }) =>
    checked ? `${color || colors.success.medium}` : `${colors.gray.light}`};
  &:hover {
    border-color: ${({ checked, color }) =>
      checked ? color || colors.success.medium : colors.gray.medium};
    background-color: ${({ checked, color }) =>
      checked ? `${color}` : 'white'};
  }
`;

const Default = styled(Flex)`
  height: 20px;
  width: 20px;
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
  background-color: ${({ checked, theme }) =>
    checked ? `${theme.primary}` : 'white'};
  border-color: ${({ checked, theme }) =>
    checked ? `${theme.primary}` : `${colors.gray.light}`};
  &:hover {
    border-color: ${({ checked, theme }) =>
      checked ? theme.primary : colors.gray.medium};
    background-color: ${({ checked, theme }) =>
      checked ? `${theme.primary}` : 'white'};
  }
`;

export default class CheckboxMain extends React.Component {
  constructor(props) {
    super(props);
    this.checkbox = React.createRef();
  }
  render() {
    const {
      disabled,
      variant,
      checked,
      onChange,
      label,
      labelStyle,
      color,
      checkboxStyle,
      error,
      success,
    } = this.props;
    if (disabled) {
      return (
        <Flex style={{ lineHeight: '20px' }} alignCenter>
          <Disabled>
            <ButtonWithIcon
              iconName="Done"
              iconProps={{ size: 19 }}
              disabled={disabled}
              color={checked ? `${colors.gray.medium}` : 'transparent'}
              onClick={() => this.checkbox.click()}
            />
            <input
              type="checkbox"
              checked={checked}
              style={{ display: 'none' }}
              ref={checkbox => (this.checkbox = checkbox)}
              onChange={onChange}
            />
          </Disabled>
          {label && (
            <Label
              style={{ ...labelStyle }}
              onClick={() => this.checkbox.click()}
            >
              {label}
            </Label>
          )}
        </Flex>
      );
    }
    if (variant === 'success' || success) {
      return (
        <Flex style={{ lineHeight: '20px' }} alignCenter>
          <Success checked={checked} style={{ ...checkboxStyle }} color={color}>
            <ButtonWithIcon
              iconName="Done"
              iconProps={{ size: 19 }}
              color={checked ? `${colors.success.medium}` : 'transparent'}
              onClick={() => this.checkbox.click()}
            />
            <input
              type="checkbox"
              checked={checked}
              style={{ display: 'none' }}
              ref={checkbox => (this.checkbox = checkbox)}
              onChange={onChange}
            />
          </Success>
          {label && (
            <Label
              style={{ ...labelStyle }}
              onClick={() => this.checkbox.click()}
            >
              {label}
            </Label>
          )}
        </Flex>
      );
    }
    if (variant === 'error' || error) {
      return (
        <Flex style={{ lineHeight: '20px' }} alignCenter>
          <Error checked={checked} style={{ ...checkboxStyle }} color={color}>
            <ButtonWithIcon
              iconName="Done"
              iconProps={{ size: 19 }}
              color={checked ? 'white' : 'transparent'}
              onClick={() => this.checkbox.click()}
            />
            <input
              type="checkbox"
              checked={checked}
              style={{ display: 'none' }}
              ref={checkbox => (this.checkbox = checkbox)}
              onChange={onChange}
            />
          </Error>
          {label && (
            <Label
              style={{ ...labelStyle }}
              onClick={() => this.checkbox.click()}
            >
              {label}
            </Label>
          )}
        </Flex>
      );
    }
    if (!variant || variant === 'default') {
      return (
        <Flex style={{ lineHeight: '20px' }} alignCenter>
          <Default checked={checked} style={{ ...checkboxStyle }} color={color}>
            <ButtonWithIcon
              iconName="Done"
              iconProps={{ size: 19 }}
              color={checked ? 'white' : 'transparent'}
              onClick={() => this.checkbox.click()}
            />
            <input
              type="checkbox"
              checked={checked}
              style={{ display: 'none' }}
              ref={checkbox => (this.checkbox = checkbox)}
              onChange={onChange}
            />
          </Default>
          {label && (
            <Label
              style={{ ...labelStyle }}
              onClick={() => this.checkbox.click()}
            >
              {label}
            </Label>
          )}
        </Flex>
      );
    }
  }
}
